import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BtnTransparent } from '../shared/Button';

const TRUCK_TYPES = [
  { label: 'Box Truck', value: 'box_truck' },
  { label: 'Semi', value: 'semi' },
  { label: 'Hotshot', value: 'hotshot' },
  { label: 'Other', value: 'other' },
];

const EXCLUDED_KEYS = ["id", "truck_type", "driver_id"];

const initialFormData = {
    id: "",
    truck_no: "",
    truck_type: "box_truck",
    vin_no: "",
    license_plate: '',
    year: "",
    make: "",
    model: "",
    dimensions: "",
    driver_id: "",
  };

  const initialErrorState = {
    year: '',
    make: '',
    model: '',
  };

export default function TruckModal({ visible, onHide, truckData, onSave, drivers, isLoading }) {
  const [errors, setErrors] = useState(initialErrorState);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (truckData) {
      const filteredData = Object.keys(initialFormData).reduce((acc, key) => {
        acc[key] = truckData[key] || '';
        return acc;
      }, {});

      setFormData(filteredData);
    } else {
      setFormData(initialFormData);
    }
    setErrors(initialErrorState);
  }, [truckData, visible]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleDropdownChange = (e, field) => {
    setFormData({ ...formData, [field]: e.value });
    setErrors({ ...errors, [field]: '' });
  };

  const handleDriverChange = (e) => {
    setFormData({ ...formData, driver_id: e.value || '' });
  };

  const handleSubmit = () => {
    const newErrors = {};
    const requiredFields = ['year', 'make', 'model'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace(/_/g, ' ')} is required`;
      }
    });

    if (Object.keys(newErrors).length === 0) {
      onSave(formData);
    } else {
      setErrors(newErrors);
    }
  };

  const footer = (
    <BtnTransparent
      name={truckData?.id ? 'Update' : 'Save'}
      handleClick={handleSubmit}
      className="float-right mt-6"
      isSubmitting={isLoading}
    />
  );

  return (
    <Dialog
      header="Truck Details"
      footer={footer}
      className="rounded-lg"
      style={{ width: '95vw', maxWidth: '1024px' }}
      visible={visible}
      onHide={onHide}
    >
      <div>
        {Object.keys(formData).map((key) => {
          return !EXCLUDED_KEYS.includes(key) ? (
            <div key={key} className="mb-3 space-y-3">
              <label htmlFor={key} className="text-white">{key.replace(/_/g, ' ').toUpperCase()}</label>
              <InputText
                id={key}
                name={key}
                className="rounded-lg border border-gray-300 p-2 w-full"
                value={formData[key]}
                onChange={handleChange}
                placeholder={`Enter ${key.replace(/_/g, ' ')}`}
              />
              {errors[key] && (
                <small className="p-error">{errors[key]}</small>
              )}
            </div>
          ) : key === 'truck_type' ? (
            <div key={key} className="mb-3">
              <label htmlFor={key} className="text-white">TYPE</label>
              <Dropdown
                id={key}
                value={formData.truck_type}
                options={TRUCK_TYPES}
                onChange={(e) => handleDropdownChange(e, 'truck_type')}
                placeholder="Select Type"
                className="w-full"
              />
            </div>
          ) : null;
        })}

        <div className="mb-3">
          <label htmlFor="driver_id" className="text-white">ASSIGNED DRIVER</label>
          <Dropdown
            id="driver_id"
            value={formData.driver_id}
            options={drivers.map(driver => ({ label: `${driver.first_name} ${driver.last_name}`, value: driver.id }))}
            onChange={handleDriverChange}
            showClear
            placeholder="Select Driver"
            className="w-full"
          />
        </div>
      </div>
    </Dialog>
  );
}
