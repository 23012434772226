import axios from 'axios';

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT || 'http://localhost:4000',
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    'X-Key-Inflection': 'camel',
    'Authorization': token ? `Bearer ${token}` : '',
  }
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  const companyId = sessionStorage.getItem('currentCompanyId') || localStorage.getItem('currentCompanyId');
  if (companyId) {
    config.headers["CurrentCompanyId"] = companyId;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response !== undefined && error.response.status === 401 && error.response.data?.message === "Your session expired. Please sign in again to continue.") {
    localStorage.removeItem('userId');
    localStorage.removeItem('fullName');
    localStorage.removeItem('token');
    localStorage.removeItem('currentCompanyId');
    localStorage.removeItem('userRole');
    sessionStorage.removeItem('currentCompanyId');
    window.location = "/";
    return new Promise(() => {});
  }
  return Promise.reject(error);
});

export default axiosInstance;
