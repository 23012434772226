import React, { useState, useEffect } from 'react';
import DataTablePaginator from "./table-view/TableVIew";
import DriverModal from './modals/DriverModal';
import { useToast } from '../contexts/ToastContext';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './shared/Button';
import http from '../common/http';

const Driver = () => {
  const [drivers, setDrivers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    fetchDriverData();
  }, []);

  const headers = [
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
    { field: "email", header: "Email" },
    { field: "phone_number", header: "Phone" },
    { field: "license_number", header: "License Number" },
  ];

  const fetchDriverData = (searchTerm = null) => {
    const params = {};
  
    if (searchTerm) {
      params.search = searchTerm;
    }
  
    http.get('/api/v1/drivers', { params })
      .then((res) => {
        setDrivers(res.data.drivers)
      })
      .catch((error) => {
        showToast('error', `Failed to fetch drivers data. ${error.response?.data?.message}`);
      });
  };

  const handleModal = (truck = null) => {
    setSelectedDriver(truck);
    setIsLoading(false);
    setModalVisible(true);
  };

  const handleSave = (driverData) => {
    setIsLoading(true);
    const params = {
      driver: {
        email: driverData.email,
        first_name: driverData.first_name,
        last_name: driverData.last_name,
        phone_number: driverData.phone_number,
        role: 'driver',
        truck_id: driverData.truck_id,
        license_number: driverData.license_number,
      }
    };

    if (selectedDriver) {
      http.put(`/api/v1/drivers/${selectedDriver.id}`, params)
        .then(() => {
          fetchDriverData();
          showToast('success', 'Driver updated successfully');
          setModalVisible(false);
        } )
        .catch(() => {
          showToast('error', 'Failed to update driver')
          setIsLoading(false);
        });
    } else {
      http.post('/api/v1/drivers', params)
        .then(() => {
          fetchDriverData();
          showToast('success', 'Driver added successfully');
          setModalVisible(false);
        })
        .catch(() => {
          showToast('error', 'Failed to add driver');
          setIsLoading(false);
        });
    }
  };

  const handleSendInvite = (driver) => {
    
    http.post(`/api/v1/drivers/${driver.id}/invite`)
      .then(() => showToast('success', 'Invite sent successfully'))
      .catch(() => showToast('error', 'Failed to send invite'));
  };

  const handleCopyInviteLink = async (user) => {
    try {
      await navigator.clipboard.writeText(user.invite_link);
      showToast('success', 'Invite link copied to clipboard')
    } catch (err) {
      showToast('error', 'Failed to copy invite link')
    }
  };

  const handleDelete = () => {
    if (selectedDriver) {
      http.delete(`/api/v1/drivers/${selectedDriver.id}`)
        .then(() => {
          fetchDriverData();
          showToast('success', 'Driver deleted successfully');
        })
        .catch(() => showToast('error', 'Failed to delete driver'));
      setDeleteModalVisible(false);
    }
  };

  return (
    <div>
      <DataTablePaginator
        headers={headers}
        data={drivers}
        buttonName="Add Driver"
        handleClick={() => handleModal()}
        onEdit={handleModal}
        onDelete={(truck) => {
          setSelectedDriver(truck);
          setDeleteModalVisible(true);
        }}
        onSendInvite={handleSendInvite}
        onCopyInviteLink={handleCopyInviteLink}
        onSearch={fetchDriverData}
      />
      <DriverModal
        visible={modalVisible}
        onHide={() => setModalVisible(false)}
        driverData={selectedDriver}
        onSave={handleSave}
        isLoading={isLoading}
      />
      <Dialog
        header="Confirm Delete"
        visible={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        headerClassName="bg-red-600 text-white"
        style={{ width: '90vw', maxWidth: '400px' }}
        footer={
          <div className="flex justify-end space-x-4">
            <BtnTransparent name="Cancel" handleClick={() => setDeleteModalVisible(false)} />
            <BtnTransparent name="Delete" handleClick={handleDelete} />
          </div>
        }
      >
        <p className="text-white">Are you sure you want to delete this driver?</p>
      </Dialog>
    </div>
  );
};

export default Driver;
