import React, { useState, useEffect } from 'react';
import http from '../common/http';
import DataTable from "./table-view/TableVIew";
import { useToast } from '../contexts/ToastContext';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './shared/Button';
import { TabView, TabPanel } from 'primereact/tabview';
import SignUpForm from './auth/sign-up/SignUp';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../common/auth';
import { isSuperAdmin } from '../utils/helper/role';

const Admin = () => {
  const [data, setData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalState, setModalState] = useState({ visible: false, type: null, user: null });
  const [loading, setLoading] = useState(true);
  const showToast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !isSuperAdmin()) {
      navigate('/');
      return;
    }  
    setLoading(false);
  }, [navigate]);

  const truckOwnerHeaders = [
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
  ];

  const carrierHeaders = [
    { field: "company", header: "Company" },
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
    { field: "email", header: "Email" },
    { field: "phone_number", header: "Phone" },
  ];

  const tabConfig = [
    { key: 'truckOwners', header: 'Truck Owner', addButtonLabel: 'Add Truck Owner', role: 'single_truck_owner', tableHeaders: truckOwnerHeaders },
    { key: 'carriers', header: 'Carrier', addButtonLabel: 'Add Carrier', role: 'carrier', tableHeaders: carrierHeaders },
  ];

  const modalData = activeIndex === 0 ? 'Truck Owner' : 'Carrier';

  const fetchData = (type, searchTerm = "") => {
    const endpoint = type === 'truckOwners'
      ? '/api/v1/users/fetch_truck_owners'
      : '/api/v1/users/fetch_carriers';

    const params = {};
  
    if (searchTerm) {
      params.search = searchTerm;
    }

    http
      .get(endpoint, { params })
      .then((res) => setData(res.data))
      .catch((error) =>
        showToast('error', `Failed to fetch ${type.replace('_', ' ')} data. ${error.response?.data?.message}`)
      );
  };

  const handleSearch = (searchTerm) => {
    fetchData(tabConfig[activeIndex].key, searchTerm);
  }

  useEffect(() => {
    if (!modalState.visible && !loading) {
      fetchData(tabConfig[activeIndex].key);
    }
  }, [activeIndex, modalState.visible, loading]);

  const handleModal = (obj = null, type = null) => {
    setSelectedUser(obj);
    setModalState({ visible: true, type: type || 'add', user: obj });
  };

  const closeModal = () => setModalState({ visible: false, type: null, user: null });

  const handleDelete = () => {
    http
      .delete(`/api/v1/users/${selectedUser.id}`)
      .then(() => {
        fetchData(tabConfig[activeIndex].key);
        setDeleteModalVisible(false);
        showToast('success', `${modalData} has been deleted successfully.`)
      })
      .catch(() => showToast('error', `Failed to delete ${modalData}`));
  };

  const handleSendInvite = (user) => {
    http.post(`/api/v1/drivers/${user.id}/invite`)
      .then(() => showToast('success', 'Invite sent successfully'))
      .catch(() => showToast('error', 'Failed to send invite'));
  };
  const handleCopyInviteLink = async (user) => {
    try {
      await navigator.clipboard.writeText(user.invite_link);
      showToast('success', 'Invite link copied to clipboard')
    } catch (err) {
      showToast('error', 'Failed to copy invite link')
    }
  }

  const userRole = activeIndex === 0 ? 'single_truck_owner' : 'carrier';
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pt-2">
      <TabView
        className="admin-tabs"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {tabConfig.map((tab, index) => (
          <TabPanel key={index} header={tab.header}>
            <DataTable
              headers={tab.tableHeaders}
              data={data}
              buttonName={tab.addButtonLabel}
              handleClick={() => handleModal()}
              onSearch={handleSearch}
              onEdit={(user) => handleModal(user, 'edit')}
              onDelete={(user) => {
                setSelectedUser(user);
                setDeleteModalVisible(true);
              }}
              onSendInvite={handleSendInvite}
              onCopyInviteLink={handleCopyInviteLink}
            />
          </TabPanel>
        ))}
      </TabView>

      {modalState.visible && (
        <SignUpForm
          isOpen={modalState.visible}
          headerName={modalState.type === 'edit' ? 'Edit User' : 'Add User'}
          userRole={userRole}
          userData={modalState.user}
          onClose={closeModal}
        />
      )}

      <Dialog
        header="Confirm Delete"
        visible={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        style={{ width: '90vw', maxWidth: '450px' }}
        headerClassName="text-2xl font-normal text-white px-6 pt-8"
        footer={
          <div className="flex justify-end space-x-4">
            <BtnTransparent name="Cancel" handleClick={() => setDeleteModalVisible(false)} />
            <BtnTransparent name="Delete" handleClick={handleDelete} />
          </div>
        }
      >
        <p className="text-white">Are you sure you want to delete this {modalData}?</p>
      </Dialog>
    </div>
  );
};

export default Admin;
